import Service from '@/js/service.js';

const service = new Service(`${process.env.VUE_APP_HELP_SERVICE}/help-service`);

const helpService = {
    getList(then, error) {
        service.publicGetRequest(1, 'list', null, then, error);
    },
    getText(id, then, error) {
        service.publicGetRequest(1, 'text/{id}', null, then, error, id);
    },
};

export default helpService;