<template>
  <div style="display: contents;">
    <slot />

    <!-- old dialogs, without UiDialog -->
    <template v-if="!isUiDialog">
      <template
        v-for="dialog of dialogsMap"
      >
        <BlackWhiteListsDialog
          v-if="dialog[0] === 'BlackWhiteListsDialog'"
          v-bind="dialog[1].data"
          :close-method="dialog[1].closeMethod"
          :key="dialog[0]"
        />

        <ExplorerDialog
          v-if="dialog[0] === 'ExplorerDialog'"
          v-bind="dialog[1].data"
          :key="dialog[0]"
        />

        <ScheduleTestsDialog
          v-if="dialog[0] === 'ScheduleTestsDialog'"
          v-bind="dialog[1].data"
          :key="dialog[0]"
        />

        <!-- to be on top of any modal, should be last in layout -->
        <YesNoDialog
          v-if="dialog[0] === 'YesNoDialog'"
          v-bind="dialog[1].data"
          :key="dialog[0]"
        />
      </template>
    </template>

    <!-- Components that utilize UiDialog -->
    <!-- all new components that are created with UiDialog should be placed in dialogs-root -->
    <!-- use "isUiDialog" option in "openDialog(name, data, options)" -->
    <div
      v-if="isUiDialog"
      class="dialogs-root"
    >
      <div
        v-for="dialog of dialogsMap"
        :key="dialog[0]"
        class="dialogs-root__dialog-wrapper"
      >
        <div
          class="dialogs-root__overlay"
          :class="{
            'dialogs-root__overlay--active': true,
          }"
          @click.self="() => onOverlayClick(dialog[0])"
          @wheel.stop.prevent="(e) => handleUserScroll(e)"
        />

        <SIMConfigurationDialog
          v-if="dialog[0] === 'SIMConfigurationDialog'"
          class="dialogs-root__dialog dialogs-root__dialog--centered"
          v-bind="dialog[1].data"
        />

        <!-- to be on top on any modal, should be last in layout -->
        <YesNoDialog2
          v-if="dialog[0] === 'YesNoDialog2'"
          class="dialogs-root__dialog dialogs-root__dialog--height-auto dialogs-root__dialog--centered"
          v-bind="dialog[1].data"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent,  provide, shallowRef, computed, ref } from "vue";
import { useRouter } from "vue-router/composables"
const BlackWhiteListsDialog = () => import(/* webpackChunkName: "bw-lists-dialog" */ "@/components/specific/Dashboard/BlackWhiteListsDialog.vue");
const ExplorerDialog = () => import(/* webpackChunkName: "explorer-dialog" */ "@/components/specific/Explorer/Dialog/ExplorerDialog.vue");
const ScheduleTestsDialog = () => import(/* webpackChunkName: "schedule-test-dialog" */ "@/components/specific/ScheduleTestsDialog.vue");
const SIMConfigurationDialog = () => import(/* webpackChunkName: "sim-configuration-dialog" */ "@/components/specific/Dialogs/SIMConfigurationDialog.vue");
const YesNoDialog = () => import(/* webpackChunkName: "yes-no-dialog" */ "@/components/extended/YesNoDialog.vue");
const YesNoDialog2 = () => import(/* webpackChunkName: "yes-no-dialog-2" */ "@/components/extended/YesNoDialog2.vue");

export const NAME = "DialogsRoot";

export default defineComponent({
  name: NAME,

  components: {
    BlackWhiteListsDialog,
    ExplorerDialog,
    ScheduleTestsDialog,
    SIMConfigurationDialog,
    YesNoDialog,
    YesNoDialog2,
  },

  setup() {
    const router = useRouter()

    // type DialogPayload = {
    //   data: Record<string, unknown> | null
    //   closeMethod: ((v?: unknown) => void) | null
    // }

    // ['DialogName', DialogPayload]
    const dialogsMap = ref([])
    const isThereAnyDialog = computed(() => Boolean(dialogsMap.value.length))

    const closeMethod = shallowRef(null)

    const clearState = (dialogName) => {
      const idx = dialogsMap.value.findIndex(([name]) => name === dialogName)
      if (idx < 0) return
      dialogsMap.value.splice(idx, 1)
    }

    router.beforeEach((to, from, next) => {
      if (isThereAnyDialog.value) {
        for (const [, { closeMethod }] of dialogsMap.value) {
          closeMethod?.()
        }
      }
      next()
    })

    const getCloseMethod = (dialogName) => {
      return dialogsMap.value.find(([name]) => name === dialogName)?.[1].closeMethod
    }

    const getDialogData = (dialogName) => {
      return dialogsMap.value.find(([name]) => name === dialogName)?.[1].data
    }

    const handleUserScroll = (e) => {
      e.preventDefault()
    }

    const isUiDialog = ref(false)

    const beforeCloseMap = ref(new Map)
    const addBeforeClose = (dialogName, beforeClose) => {
      beforeCloseMap.value.set(dialogName, beforeClose)
    }

    const onOverlayClick = (dialogName) => {
      const closeMethod = getCloseMethod(dialogName)
      const beforeClose = beforeCloseMap.value.get(dialogName)
      if (beforeClose) {
        beforeClose().then((result) => {
          if (result) {
            closeMethod?.()
          }
        })
      } else {
        closeMethod?.()
      }
    }

    provide(NAME, {
      getCloseMethod,
      getDialogData,
      addBeforeClose,
      openDialog(dialogName, data, { isUiDialog: _isUiDialog = false } = {}) {
        isUiDialog.value = _isUiDialog
        return new Promise((resolve) => {
          const closeMethod = async (v) => {
            if (dialogsMap.value.length === 1 && isUiDialog.value) {
              isUiDialog.value = false
            }
            resolve(v)
          }
          dialogsMap.value.push([dialogName, { data, closeMethod }])
        }).finally(() => clearState(dialogName))
      },
    })

    return {
      closeMethod,
      handleUserScroll,
      dialogsMap,
      isUiDialog,
      onOverlayClick,
    }
  }
})
</script>

<style lang="scss">
.dialogs-root {
  position: fixed;
  inset: 0;
  z-index: 6000;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  pointer-events: none;

  &__dialog-wrapper {
    position: fixed;
    inset: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(33, 33, 33);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 6500;
    animation: overlay-fade 0.3s ease forwards;
    pointer-events: auto;
  }

  &__dialog {
    position: absolute;
    z-index: 7000;
    height: 100%;
    pointer-events: auto;
    scale: 0.8;
    animation: scale-dialog 0.2s ease forwards;

    &--centered {
      margin: 0 auto;
    }

    &--height-auto {
      height: auto;
    }

    &--right-top {
      top: 0px;
      right: 0px;
    }
  }

  @keyframes overlay-fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.46;
    }
  }

  @keyframes scale-dialog {
    from {
      scale: 0.8;
    }
    to {
      scale: 1;
    }
  }
}
</style>
