<template>
  <v-system-bar
    v-if="userInfo != null && userInfo.loggedIn == true"
    class="upperHeader white--text"
    color="upperHeader"
  >
    <v-spacer></v-spacer>

    <div
      v-if="typeof result.sdt != 'undefined' && result.sdt == 1 && menu != null && Object.keys(menu).length > 0 && result.process==''"
      class="d-flex"
    >
      <boolSwitch
          v-if="this.$route.query.f2=='testInfo' && typeof this.$route.query.function!='undefined' &&  this.$route.query.function=='getInfo'"
          title="Admin Info"
          :fieldAttributes="{ name:''}"
          @input="updateCall(...arguments)"
          :value="result.adminInfo"
      />

      <XSwitch
          title="Fiber Net"
          :value="fiberNet.getEnabled()"
          @input="fiberNet.setEnabled($event)"
      />

      <XSwitch
          title="Service Log"
          :value="$store.state.logging"
          @input="$store.commit('toggleLogging')"
      />
    </div>

    <template
        v-if="
        typeof result.process != 'undefined' && result.process == 'userView'"
    >
      <span
        class="mr-2 pr-2 mb-1" style="min-width: 150px;background-color:#ff9900;border-radius: 2px;text-align:center;"
      >
        This is User View
      </span>

      <v-icon
          style="cursor: pointer;font-size: 18px;"
          color="#00ffbc"
          title="Switch back to Super Admin!"
          @click="switchAdminView()"
          class="mr-2"
      >
        mdi-account-supervisor-circle
      </v-icon>
      |
    </template>

    <template
        v-if="
        typeof result.sdt != 'undefined' &&
        result.sdt == 1 &&
        menu != null &&
        Object.keys(menu).length > 0 &&
        result.process==''"
    >
      <v-menu offset-y left>
        <template v-slot:activator="{ on }">
          <v-btn text x-small class="white--text" v-on="on">
            <v-icon color="white">mdi-cog-transfer-outline</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-text>
            <megaMenu
                v-if="typeof mainMenuItem != 'undefined'"
                :submenuList="mainMenuItem['children']"
                :enableTabs="false"
                :tabIndex="0"
                :mainMenuItem="mainMenuItem"
                :menuItem="{}"
            />
          </v-card-text>
        </v-card>
      </v-menu>
      |
    </template>
    <!-- <notifications></notifications>|-->

    <v-dialog
      content-class="projectDialog"
      v-model="projectSwitch"
      max-width="490"
    >
      <fields
        :result="projectInfo"
        @close-dialog="projectSwitch = false"
      />
    </v-dialog>

    <v-alert
        :value="oneProject"
        type="error"
        elevation="0"
        dense
        light
        style="z-index: 100; position: absolute; right: 3px; top: 30px"
        transition="scroll-y-transition"
    >
      {{ language.componentLabel.oneProjectMessage }}
    </v-alert>

    <div v-if="!empty(userInfo.projectName) && result.disableMenu != true">
      <v-btn
          :title="upperHeaderLanguage.label_org_name+ ' | '+upperHeaderLanguage.label_project"
          @click="switchProjectRequest()"
          text
          x-small
          class="white--text"
          id="projectSwitcher"
      >
        <template v-if="$vuetify.breakpoint.xs">
          {{ trimString(userInfo.orgName) }} | {{ trimString(userInfo.projectName) }}
        </template>

        <template v-else>
          Organization: {{ trimString(userInfo.orgName) }} | {{ languagePack('topMiniHeader', 'project_label') }}: {{ trimString(userInfo.projectName) }}
        </template>
      </v-btn>|
    </div>

    <v-btn
        :title="logoutTitle"
        @click="() => doLogout()"
        text
        x-small
        class="white--text"
        id="logout"
    >
      {{
        languagePack('topMiniHeader', 'logout_label')
      }}
      <template v-if="$vuetify.breakpoint.smAndUp">
        {{ trimString(userInfo.firstname) }}
      </template>
    </v-btn>

    |
    <v-btn
        :title="upperHeaderLanguage.label_service_desk"
        href="https://service.acctopus.com"
        target="_blank"
        text
        x-small
        class="white--text">
      <span class="hidden-sm-and-down pr-1">get</span>
      support
    </v-btn>
  </v-system-bar>
</template>

<script>
import { defineComponent } from "vue"
import requests from '@/js/requests';
import megaMenu from '@/commonComponents/megaMenu.vue';
import boolSwitch from '@/commonComponents/boolSwitch.vue';
import fields from '@/commonComponents/dynamic/fields.vue';
import XSwitch from '@/components/basic/XSwitch';
import fiberNet from '@/js/fiber-net';
import { tokenRefresher } from "@/auth-tools"

export default defineComponent({
  components: {
    XSwitch,
    megaMenu,
    fields,
    boolSwitch
  },

  props: ['userInfo', 'result', 'menu'],

  data: function () {
    return {
      upperHeaderLanguage: this.userInfo.upperHeaderLanguage,
      showNotificationTray: false,
      projectSwitch: false,
      projectInfo: null,
      oneProject: false,
      fiberNet: fiberNet,
    };
  },

  created() {
    this.$root.$refs.upperHeader = this;
  },

  computed: {
    logoutTitle: function () {
      return 'Logout ' + this.trimString(this.userInfo.firstname) + '';
      // return "Logout [User Name]";
    },
    mainMenuItem: function () {
      let systemAdministration = {};
      if (this.menu != null) {
        systemAdministration = this.menu.systemAdministration;
      }
      return systemAdministration;
    },
  },

  watch: {
    projectSwitch(newVal) {
      if (!newVal) {
        this.projectInfo = {};
      }
    },
  },

  methods: {
    doLogout() {
      tokenRefresher.logout()
    },

    updateCall(value) {
      let options = {
        requestType: 'ajax',
        showInfo: value,
        n_id: this.$route.query.n_id,
        n_id_key: this.$route.query.n_id_key,
        excludeRows: [],
        condition: typeof this.result.control!="undefined"? this.result.control.condition: true,//always as per filter!
        post: typeof this.result.control!="undefined"? this.result.control.post:false,
        pre: typeof this.result.control!="undefined"? this.result.control.pre:false
      };
      let sentURI = 'serve.php?f=testing&f2=testInfo&function=getInfoUpdate';
      let caller = this;

      requests.frameworkAxiosRequest({
        method: 'post',
        url: sentURI,
        data: options,
      })
      .then(function (response) {
            caller.$set(response.data.result.table, 'sortBy', caller.result.table.sortBy);
            caller.$set(response.data.result.table, 'sortDesc', caller.result.table.sortDesc);
            caller.$store.commit('tableChanged', response.data.result.table);
      })
      .catch(function (response) {
            //handle error
            console.error(response);
      });
    },

    trimString(value) {
      if (this.$vuetify.breakpoint.xs) {
        if (value.length > 7) {
          value = value.substring(0, 7);
          value += '.';
        }
      } else {
        if (value.length > 50) {
          value = value.substring(0, 50);
          value += '...';
        }
      }
      return value;
    },

    switchAdminView() {
      requests.frameworkAxiosRequest({
        method: 'POST',
        url: 'serve.php?f=administration&f2=superUserAdministration',
        dataType: 'json',
        data: {
          function: 'userViewBack',
          requestType: 'ajax',
        },
      })
          .then(function () {
            window.location.href = '?f=administration&f2=superUserAdministration';
          })
          .catch((error) => {
            console.error(error);
          });
    },

    showTray() {
      this.showNotificationTray = !this.showNotificationTray;
    },

    switchProjectRequest() {
      let options = {
        function: 'getSwitchProject',
        requestType: 'ajax',
        hideButtons: 'true',
      };
      let sentURI = 'serve.php?f=administration&f2=projectAdministration';
      requests.frameworkAxiosRequest({
        method: 'post',
        url: sentURI,
        data: options,
      })
          .then((response) => {
            this.projectSwitch = true;
            this.projectInfo = response.data.result;
          })
          .catch((response) => {
            //handle error
            console.error(response);
          });
    },
  },
});
</script>
<style>
.projectDialog {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
