<template>
  <div :data-help="computedHelp" class="x-select">
    <v-autocomplete
        v-if="autocomplete && !tooltip && !combobox"
        ref="select"
        :chips="computedChips"
        :class="`x-select-${color}`"
        :dense="!computedChips || dense"
        :disabled="disabled"
        :item-text="itemText"
        :item-value="itemValue"
        :items="items"
        :label="label"
        :multiple="computedMultiple"
        :rules="computedRules"
        :style="`min-height: ${minHeight}px; ${pxOrNot('width', width)}`"
        :value="dataValue"
        :search-input="searchInput"
        :menu-props="{offsetY: true}"
        :id="id"
        hide-details="auto"
        outlined
        :return-object="!itemValue"
        @input="emitInput"
        @blur="handleBlur"
        @click="$emit('click', $event)">
      <template v-if="computedChips" #selection="selection">
        <v-chip @click:close="unselectItem(selection.item)" small close>
          {{ selection.item }}
        </v-chip>
      </template>
      <template v-if="itemTooltip" #item="{item}">
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="tooltip-item v-list-item__title">{{ getItemText(item) }}</div>
          </template>
          <slot v-if="$scopedSlots['item-tooltip']" name="item-tooltip" :item="item"/>
          <span v-else>{{ typeof itemTooltip === 'function' ? itemTooltip(item) : item[itemTooltip] }}</span>
        </v-tooltip>
      </template>
    </v-autocomplete>
    <v-combobox
        v-else-if="!tooltip && combobox"
        ref="select"
        :chips="computedChips"
        :class="`x-select-${color}`"
        :dense="!computedChips || dense"
        :disabled="disabled"
        :item-text="itemText"
        :item-value="itemValue"
        :items="items"
        :label="label"
        :multiple="computedMultiple"
        :rules="computedRules"
        :style="`min-height: ${minHeight}px; ${pxOrNot('width', width)}`"
        :value="dataValue"
        :menu-props="{offsetY: true}"
        :id="id"
        hide-details="auto"
        outlined
        :return-object="!itemValue"
        @input="emitInput"
        @click="$emit('click', $event)">
      <template v-if="computedChips" #selection="selection">
        <v-chip @click:close="unselectItem(selection.item)" small close>
          {{ selection.item }}
        </v-chip>
      </template>
    </v-combobox>
    <v-select
        v-else-if="!tooltip"
        ref="select"
        :chips="computedChips"
        :class="`x-select-${color}`"
        :dense="!computedChips || dense"
        :disabled="disabled"
        :item-text="itemText"
        :item-value="itemValue"
        :items="items"
        :label="label"
        :multiple="computedMultiple"
        :rules="computedRules"
        :style="`min-height: ${minHeight}px; ${pxOrNot('width', width)}`"
        :value="dataValue"
        :menu-props="{offsetY: true}"
        :id="id"
        hide-details="auto"
        outlined
        :return-object="!itemValue"
        @input="emitInput"
        @click="$emit('click', $event)">
      <template v-if="computedChips" #selection="selection">
        <v-chip @click:close="unselectItem(selection.item)" small close>
          {{ selection.item }}
        </v-chip>
      </template>
    </v-select>
    <v-tooltip v-else v-model="showTooltip" top>
      <template v-slot:activator="{}">
        <v-autocomplete
            v-if="autocomplete"
            ref="select"
            :chips="computedChips"
            :class="`x-select-${color}`"
            :dense="!computedChips || dense"
            :disabled="disabled"
            :item-text="itemText"
            :item-value="itemValue"
            :items="items"
            :label="label"
            :multiple="computedMultiple"
            :rules="computedRules"
            :style="`min-height: ${minHeight}px; ${pxOrNot('width', width)}`"
            :value="dataValue"
            :search-input="searchInput"
            :menu-props="{offsetY: true}"
            :id="id"
            hide-details="auto"
            outlined
            :return-object="!itemValue"
            @blur="showTooltip = false"
            @focus="showTooltip = true"
            @input="emitInput"
            @click="$emit('click', $event)">
          <template v-if="computedChips" #selection="selection">
            <v-chip @click:close="unselectItem(selection.item)" small close>
              {{ selection.item }}
            </v-chip>
          </template>
        </v-autocomplete>
        <v-combobox
            v-else-if="combobox"
            ref="select"
            :chips="computedChips"
            :class="`x-select-${color}`"
            :dense="!computedChips || dense"
            :disabled="disabled"
            :item-text="itemText"
            :item-value="itemValue"
            :items="items"
            :label="label"
            :multiple="computedMultiple"
            :rules="computedRules"
            :style="`min-height: ${minHeight}px; ${pxOrNot('width', width)}`"
            :value="dataValue"
            :menu-props="{offsetY: true}"
            :id="id"
            hide-details="auto"
            outlined
            :return-object="!itemValue"
            @blur="showTooltip = false"
            @focus="showTooltip = true"
            @input="emitInput"
            @click="$emit('click', $event)">
          <template v-if="computedChips" #selection="selection">
            <v-chip @click:close="unselectItem(selection.item)" small close>
              {{ selection.item }}
            </v-chip>
          </template>
        </v-combobox>
        <v-select
            v-else
            ref="select"
            :chips="computedChips"
            :class="`x-select-${color}`"
            :dense="!computedChips || dense"
            :disabled="disabled"
            :item-text="itemText"
            :item-value="itemValue"
            :items="items"
            :label="label"
            :multiple="computedMultiple"
            :rules="computedRules"
            :style="`min-height: ${minHeight}px; ${pxOrNot('width', width)}`"
            :value="dataValue"
            :menu-props="{offsetY: true}"
            :id="id"
            hide-details="auto"
            outlined
            :return-object="!itemValue"
            @blur="showTooltip = false"
            @focus="showTooltip = true"
            @input="emitInput"
            @click="$emit('click', $event)">
          <template v-if="computedChips" #selection="selection">
            <v-chip @click:close="unselectItem(selection.item)" small close>
              {{ selection.item }}
            </v-chip>
          </template>
        </v-select>
      </template>
      <span v-if="tooltip" v-html="tooltip"/>
    </v-tooltip>
    <HelpButton :id="id" :help="computedHelp"/>
  </div>
</template>

<script>
import getRule from '@/js/rules';
import HelpButton from '@/components/basic/HelpButton.vue';
import {deepCopy, getRequiredRule, getValueByFunctionOrDirectly, pxOrNot} from '@/js/general';

export default {
  name: 'XSelect',
  components: {HelpButton},
  props: {
    value: [String, Number, Object, Array],
    label: String,
    items: Array,
    itemValue: {
      type: [String, Function],
      default: undefined,
    },
    itemText: {
      type: [String, Function],
      default: undefined,
    },
    itemTooltip: [String, Function],
    required: [Boolean, Function],
    rules: Array,
    tooltip: String,
    validateImmediately: Boolean,
    disabled: Boolean,
    color: String,
    autocomplete: {
      type: Boolean,
      default: true,
    },
    combobox: Boolean,
    id: String,
    inputType: String,
    outputType: String,
    multiple: [Boolean, Function],
    chips: [Boolean, Function],
    minHeight: [Number, String],
    click: Function,
    keepValue: Boolean,
    dense: Boolean,
    noSpacesInValues: Boolean,
    default: undefined,
    noPreselect: Boolean,
    width: [Number, String],
    help: String,
  },
  data() {
    return {
      dataValue: [String, Number, Object],
      getRequiredRule: getRequiredRule,
      showTooltip: false,
      searchInput: '',
    };
  },
  mounted() {
    if (this.validateImmediately) {
      this.dataValue = {};
      this.$nextTick(() => {
        this.reloadValue();
      });
      this.emitDefaultValue();
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.reloadValue();
      },
    },
    items() {
      this.reloadValue(null);
    },
    tooltip() {
      if (this.validateImmediately) {
        this.dataValue = {};
        this.$nextTick(() => {
          this.reloadValue();
        });
      }
    },
  },
  computed: {
    computedRules() {
      let computedRules = [];
      if (this.computedRequired) {
        computedRules.push(getRequiredRule());
      }
      if (this.rules) {
        for (const rule of this.rules) {
          if (typeof rule === 'string') computedRules.push(getRule(rule));
          else computedRules.push(rule);
        }
      }
      if (!computedRules.length) computedRules = undefined;
      return computedRules;
    },
    computedRequired() {
      return getValueByFunctionOrDirectly(this.required);
    },
    computedMultiple() {
      return getValueByFunctionOrDirectly(this.multiple);
    },
    computedChips() {
      return getValueByFunctionOrDirectly(this.chips);
    },
    computedHelp() {
      if (this.help) return this.help;
      return this.id;
    },
  },
  methods: {
    pxOrNot,
    reloadValue() {
      let value = this.value;

      let defaultValueSet = false;
      if (this.computedRequired && !this.noPreselect && !value && this.items && this.items.length) {
        value = this.items[0];
        if (typeof value === 'object') {
          if (this.itemValue) value = value[this.itemValue];
          else if ('value' in value) value = value.value;
        } else {
          value = this.items[0];
        }
        defaultValueSet = true;
      }

      switch (this.inputType) {
        case 'string':
          value = value.toString();
          break;
        case 'int':
          value = parseInt(value);
          break;
        case 'float':
          value = parseFloat(value);
          break;
        default:
          break;
      }

      if (defaultValueSet) {
        this.$emit('input', value);
      }

      if (value !== this.dataValue) this.dataValue = value;
    },
    emitInput(value) {
      if (this.computedRequired && value === null) return;
      const oldValue = deepCopy(this.value);
      switch (this.outputType) {
        case 'string':
          value = value.toString();
          break;
        case 'int':
          value = parseInt(value);
          break;
        case 'float':
          value = parseFloat(value);
          break;
        default:
          break;
      }
      if (this.noSpacesInValues && Array.isArray(value)) {
        const newValue = [];
        for (const valueItem of value) {
          if (typeof valueItem === 'string') {
            newValue.push(valueItem.replace(' ', ''));
          }
        }
        value = newValue;
      }
      this.$emit('input', value);
      this.$emit('modified', value);
      if (this.keepValue) {
        this.$nextTick(() => {
          this.dataValue = null;
          this.$nextTick(() => {
            this.dataValue = oldValue;
          });
        });
      }
    },
    unselectItem(item) {
      const value = this.dataValue.filter(x => x !== item);
      this.emitInput(value);
    },
    handleBlur() {
      if (this.computedRequired && this.searchInput === '') {
        this.dataValue = null;
        this.$nextTick(this.reloadValue);
      }
    },
    getItemText(item) {
      if (this.itemText) {
        if (typeof this.itemText === 'function') return this.itemText(item);
        return item[this.itemText];
      }
      return item;
    },
  },
};
</script>

<style scoped>
/deep/ .x-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections {
  flex-wrap: nowrap;
}

/deep/ .x-input.v-text-field.v-text-field--enclosed > .v-input__control > .v-text-field__details {
  margin-bottom: -3px;
}

/deep/ .v-input--is-disabled .v-input__append-inner {
  visibility: hidden;
}

/deep/ .x-select-success .v-select__slot > input {
  color: var(--v-success-base);
}

.x-select >>> .v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense .v-select__selections {
  padding: 10px 0 6px 0;
  gap: 3px;
}

.x-select >>> .v-select.v-input--dense .v-chip {
  margin: 0;
}

.x-select {
  display: flex;
}

.x-select >>> .help-button {
  margin-top: 2px;
}

.v-list >>> .v-list-item:has(.tooltip-item) {
  padding: 0;
}

.tooltip-item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}
</style>