<template>
  <div v-if="visible" :data-help="computedHelp" class="help-button">
    <XBtn
        icon="mdi-help-circle" @click.stop="showDialog"/>
    <div
        v-if="shouldShowModal"
        v-click-outside="hideDialog"
        class="help-button__dialog-container"
        ref="draggableContainer"
        id="draggable-container"
        :style="customStyle"
        @mousedown="dragMouseDown">
      <v-card
          elevation="1" max-width="500" min-height="200" min-width="500">
        <v-list
          class="help-button__dialog-header"
          color="#2b5593"
        >
          <v-list-item class="white--text">
            <v-list-item-title>
              Help
            </v-list-item-title>

            <v-btn
                icon @click="pinned = !pinned" v-if="!pinned">
              <v-icon title="Pin" color="white">mdi-pin</v-icon>
            </v-btn>

            <v-btn
                icon @click="closeDialog" v-if="pinned">
              <v-icon title="Close" color="white">mdi-close</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-md-preview :text="helpContent"></v-md-preview>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import XBtn from '@/components/basic/XBtn';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import helpService from '@/js/services/HelpService';

// highlightjs
import hljs from 'highlight.js';

VMdPreview.use(githubTheme, {
  Hljs: hljs,
});

export default defineComponent({
  name: 'HelpButton',
  components: {
    XBtn,
    VMdPreview,
  },
  props: {
    id: String,
    help: String,
  },
  data() {
    return {
      //text: '',
      list: [],
      pinned: false,
      helpContent: 'No content!',
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      shouldShowModal: false,
      customStyle: {},
    };
  },
  created() {
    if (!this.computedHelp || !this.visible) return;
    this.getText();
  },
  computed: {
    computedHelp() {
      return this.id || this.help;
    },
    visible() {
      return this.$store.state.helpList.includes(this.computedHelp);
    },
  },
  methods: {
    getText() {
      helpService.getText(this.computedHelp, (text) => {
        this.helpContent = text;
      });
    },
    showDialog(event) {
      this.getText();
      event.preventDefault();
      //this.helpContent = this.text;
      this.shouldShowModal = true;
      this.pinned = false;
      //console.log("X-----",event.clientX)
      //console.log("y---",event.clientY)
      this.$nextTick(() => {
        let dialogID = document.getElementById('dialog_form');
        let pageWrapID = document.getElementById('pageWarper');
        if (typeof dialogID !== 'undefined' && dialogID !== null) {
          // console.log("window width--->",dialogID.offsetWidth);
          // console.log("window height--->",dialogID.offsetHeight);
          let dialogWidth = dialogID.offsetWidth;
          this.setStyleRight(dialogWidth, event.clientX);
          let dialogHeight = dialogID.offsetHeight;
          this.setStyleBottom(dialogHeight, event.clientY);// for dialog case
        } else if (typeof pageWrapID !== 'undefined' && pageWrapID !== null) {
          //console.log("page width--->",pageWrapID.offsetWidth);
          let pageWrapWidth = pageWrapID.offsetWidth;
          this.setStyleRight(pageWrapWidth, event.clientX);
        }
      });
    },
    setStyleRight(baseWidth, clientX) {
      let variation = Math.abs(baseWidth - clientX);
      //console.log("var---",variation)
      if (variation < 600) {
        variation = 45;
        this.$set(this.customStyle, 'right', variation + 'px');
      }
    },
    setStyleBottom(baseHeight, clientY) {
      let variation = Math.abs(baseHeight - clientY);
      //console.log("var Height---",baseHeight)
      //console.log("clientY---",clientY)
      //console.log("var ---",variation)
      if (variation < 100) {
        variation = 60;
        this.$set(this.customStyle, 'bottom', variation + 'px');
      }
    },
    hideDialog() {
      if (!this.pinned) {
        this.shouldShowModal = false;
      }
    },
    closeDialog() {
      this.shouldShowModal = false;
    },
    dragMouseDown: function (event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      this.$refs.draggableContainer.style.top =
          (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px';
      this.$refs.draggableContainer.style.left =
          (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px';
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
});
</script>

<style lang="scss">
.help-button {
  &__dialog-container {
    position: absolute;
    z-index: 99999;
  }

  &__dialog-header {
    cursor: move;
  }
}

#draggable-container >>> .github-markdown-body {
  padding: 7px 7px 7px 7px;
}
</style>
